<template>
  <div>
    <v-container>
      <v-row align="center">
        <v-col cols="auto" class="pr-12">
          <h1>Property Enquiries</h1>
        </v-col>
        <v-col class="pl-12">
          <v-text-field
            label="Search Property Enquiries"
            v-model="searchTerm"
            outlined
            dense
            hide-details
            prepend-inner-icon="mdi-magnify"
          ></v-text-field>
        </v-col>
        <v-col cols="auto">
          <v-row align="center" justify="end" no-gutters>
            <v-tooltip bottom>
              <template v-slot:activator="{ on }">
                <v-btn
                  depressed
                  light
                  v-on="on"
                  :to="{ name: $route.name + '-archived' }"
                >
                  <v-icon small>mdi-archive</v-icon>
                </v-btn>
              </template>
              <span>View Archive Property Enquiries</span>
            </v-tooltip>
          </v-row>
        </v-col>
      </v-row>

      <v-data-table
        :headers="tableHeaders"
        :items="enquiries"
        class="mt-4"
        no-data-text="No Enquiries found"
      >
        <template v-slot:item.actions="{ item }">
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                x-small
                depressed
                color="green lighten-4 green--text"
                class="mr-2"
                :to="{
                  name: 'module-ibm-enquiries-individual',
                  params: { enquiryId: item.id },
                }"
              >
                <v-icon x-small>mdi-eye</v-icon>
              </v-btn>
            </template>
            <span>View</span>
          </v-tooltip>

          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                x-small
                depressed
                color="blue lighten-4 blue--text"
                v-on="on"
                class="mr-2"
                @click="$refs.enquiryDialog.open(item)"
              >
                <v-icon x-small>mdi-pencil</v-icon>
              </v-btn>
            </template>
            <span>Edit</span>
          </v-tooltip>
          <v-tooltip bottom>
            <template v-slot:activator="{ on }">
              <v-btn
                x-small
                depressed
                color="red lighten-4 red--text"
                v-on="on"
                @click="openDelete(item)"
              >
                <v-icon x-small>mdi-archive</v-icon>
              </v-btn>
            </template>
            <span>Archive</span>
          </v-tooltip>
        </template>
      </v-data-table>
    </v-container>
    <enquiry-dialog ref="enquiryDialog" />
    <v-dialog v-model="deleteEnquiry.dialog" persistent max-width="400">
      <v-card>
        <v-card-title class="headline">Archive Enquiry</v-card-title>
        <v-card-text
          >Are you sure you want to archive this enquiry form?</v-card-text
        >
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="error" depressed @click="resetDelete">No, cancel</v-btn>
          <v-btn
            color="success"
            depressed
            :loading="deleteEnquiry.loading"
            @click="saveDelete"
            >Yes</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import EnquiryDialog from "./components/EnquiryDialog.vue";

export default {
  components: {
    EnquiryDialog,
  },

  data() {
    return {
      deleteEnquiry: {
        dialog: false,
        enquiry: {},
        loading: false,
      },
      groups: [],
      tableHeaders: [
        { text: "Property", value: "property_interested" },
        { text: "First Name", value: "first_name" },
        { text: "Last Name", value: "last_name" },
        { text: "Actions", value: "actions", sortable: false, align: "right" },
      ],
      searchTerm: "",
      breadcrumbs: [
        {
          text: "Enquiries",
          disabled: true,
        },
        {
          text: "All",
          disabled: true,
        },
      ],
    };
  },

  computed: {
    enquiries() {
      let enquiries = this.$store.getters["ibm/enquiriesStore/all"];

      if (this.searchTerm !== "") {
        enquiries = enquiries.filter((p) => {
          const property_interested = p.property_interested.toLowerCase();
          const searchTerm = this.searchTerm.toLowerCase();

          return property_interested.includes(searchTerm);
        });
      }

      return enquiries;
    },
  },

  methods: {
    openDelete(enquiry) {
      this.deleteEnquiry.enquiry = enquiry;
      this.deleteEnquiry.dialog = true;
    },

    resetDelete() {
      this.deleteEnquiry.dialog = false;
      this.deleteEnquiry.enquiry = {};
      this.deleteEnquiry.loading = false;
    },

    saveDelete() {
      this.deleteEnquiry.loading = true;

      this.$store
        .dispatch("ibm/enquiriesStore/deleteEnquiry", {
          appId: this.$route.params.id,
          enquiryId: this.deleteEnquiry.enquiry,
        })
        .then(() => {
          this.resetDelete();
        })
        .catch(() => {
          this.deleteEnquiry.loading = false;
        });
    },
  },
};
</script>
