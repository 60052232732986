<template>
  <v-dialog
    v-model="dialog"
    scrollable
    max-width="600"
    @click:outside="resetForm()"
  >
    <v-card>
      <v-card-title class="headline"
        >{{ isEditing ? "Edit" : "Add a new" }} Enquiry</v-card-title
      >
      <v-divider></v-divider>
      <v-card-text class="pt-5 grey lighten-4">
        <v-form @submit.prevent="saveForm" method="post" id="note-form">
          <v-text-field
            label="Property Address Line 1"
            v-model="fields.property_address_line1"
            type="text"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('property_address_line1')"
            :error-messages="errors['property_address_line1']"
          ></v-text-field>

          <v-text-field
            label="Property Address Line 2"
            v-model="fields.property_address_line2"
            type="text"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('property_address_line2')"
            :error-messages="errors['property_address_line2']"
          ></v-text-field>

          <v-text-field
            label="Property Address Town"
            v-model="fields.property_address_town"
            type="text"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('property_address_town')"
            :error-messages="errors['property_address_town']"
          ></v-text-field>

          <v-text-field
            label="Property Address Region"
            v-model="fields.property_address_region"
            type="text"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('property_address_region')"
            :error-messages="errors['property_address_region']"
          ></v-text-field>
          <v-text-field
            label="Property Address Postcode"
            v-model="fields.property_address_postcode"
            type="text"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('property_address_postcode')"
            :error-messages="errors['property_address_postcode']"
          ></v-text-field>

          <v-text-field
            label="Landlord Full Name"
            v-model="fields.landlord_full_name"
            type="text"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('landlord_full_name')"
            :error-messages="errors['landlord_full_name']"
          ></v-text-field>

          <v-text-field
            label="Phone"
            v-model="fields.phone"
            type="text"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('phone')"
            :error-messages="errors['phone']"
          ></v-text-field>

          <v-text-field
            label="Email"
            v-model="fields.email"
            type="text"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('email')"
            :error-messages="errors['email']"
          ></v-text-field>

          <v-select
            label="Agreement Type"
            v-model="fields.agreement_type"
            :items="types"
            item-value="name"
            item-text="label"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('agreement_type')"
            :error-messages="errors['agreement_type']"
          ></v-select>

          <v-select
            label="Assignee"
            v-model="fields.assignee"
            :items="users"
            item-value="id"
            item-text="full_name"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('assignee')"
            :error-messages="errors['assignee']"
          ></v-select>
          <v-text-field
            label="Due Date"
            v-model="fields.due_date"
            type="date"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('due_date')"
            :error-messages="errors['due_date']"
          ></v-text-field>

          <v-text-field
            label="No of Bedrooms"
            v-model="fields.no_of_bedrooms"
            type="number"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('no_of_bedrooms')"
            :error-messages="errors['no_of_bedrooms']"
          ></v-text-field>

          <v-text-field
            label="Rent Value"
            v-model="fields.rent_value"
            type="text"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('rent_value')"
            :error-messages="errors['rent_value']"
          ></v-text-field>

          <v-switch
            label="Commission"
            v-model="fields.commission"
            inset
            :error="errors.hasOwnProperty('commission')"
            :error-messages="errors['commission']"
          ></v-switch>

          <v-text-field
            label="Contact Method"
            v-model="fields.contact_method"
            type="text"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('contact_method')"
            :error-messages="errors['contact_method']"
          ></v-text-field>
          <v-text-field
            label="Current Provider"
            v-model="fields.current_provider"
            type="text"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('current_provider')"
            :error-messages="errors['current_provider']"
          ></v-text-field>

          <v-text-field
            label="Status"
            v-model="fields.status"
            type="text"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('status')"
            :error-messages="errors['status']"
          ></v-text-field>
          <v-textarea
            label="Lost Reason"
            v-model="fields.lost_reason"
            type="text"
            outlined
            background-color="white"
            :error="errors.hasOwnProperty('lost_reason')"
            :error-messages="errors['lost_reason']"
          ></v-textarea>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="secondary" text @click="resetForm()">Close</v-btn>
        <v-btn
          color="accent"
          text
          type="submit"
          :loading="loading"
          form="note-form"
          >Save</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  data() {
    return {
      fields: {
        property_address_line1: null,
        property_address_line2: null,
        property_address_town: null,
        property_address_region: null,
        property_address_postcode: null,
        landlord_full_name: null,
        phone: null,
        email: null,
        agreement_type: null,
        assignee: null,
        due_date: null,
        no_of_bedrooms: null,
        rent_value: null,
        commission: false,
        contact_method: null,
        current_provider: null,
        status: null,
        lost_reason: null,
      },
      dialog: false,
      loading: false,
      isEditing: false,
      errors: {},
      types: [
        { label: "Managed Let", name: "Managed Let" },
        { label: "Let Only", name: "Let Only" },
      ],
    };
  },

  computed: {
    users() {
      return this.$store.state.users["users"];
    },
  },

  methods: {
    open: function (enquiry = null) {
      if (enquiry !== null) {
        this.enquiry = enquiry;

        this.fields.property_address_line1 = enquiry.property_address_line1;
        this.fields.property_address_line2 = enquiry.property_address_line2;
        this.fields.property_address_town = enquiry.property_address_town;
        this.fields.property_address_region = enquiry.property_address_region;
        this.fields.property_address_postcode =
          enquiry.property_address_postcode;
        this.fields.landlord_full_name = enquiry.landlord_full_name;
        this.fields.phone = enquiry.phone;
        this.fields.email = enquiry.email;
        this.fields.agreement_type = enquiry.agreement_type;
        this.fields.assignee = enquiry.assignee;
        this.fields.due_date = enquiry.due_date;
        this.fields.no_of_bedrooms = enquiry.no_of_bedrooms;
        this.fields.rent_value = enquiry.rent_value;
        this.fields.commission = enquiry.commission;
        this.fields.contact_method = enquiry.contact_method;
        this.fields.current_provider = enquiry.current_provider;
        this.fields.status = enquiry.status;
        this.fields.lost_reason = enquiry.lost_reason;
        this.isEditing = true;
      }

      this.dialog = true;
    },

    saveForm() {
      this.loading = true;
      this.errors = {};

      let payload = {
        appId: this.$route.params.id,
        isEditing: this.isEditing,
        fields: this.fields,
      };

      if (this.isEditing) {
        payload.enquiryId = this.enquiry.id;
      }

      this.$store
        .dispatch("ibm/enquiriesStore/saveEnquiry", payload)
        .then(() => {
          this.resetForm();
        })
        .catch((err) => {
          this.errors = err.response.data.errors;
          this.loading = false;
        });
    },

    resetForm: function () {
      this.dialog = false;
      this.loading = false;
      this.isEditing = false;
      this.fields = {
        property_address_line1: null,
        property_address_line2: null,
        property_address_town: null,
        property_address_region: null,
        property_address_postcode: null,
        landlord_full_name: null,
        phone: null,
        email: null,
        agreement_type: null,
        assignee: null,
        due_date: null,
        no_of_bedrooms: null,
        rent_value: null,
        commission: false,
        contact_method: null,
        current_provider: null,
        status: null,
        lost_reason: null,
      };
    },
  },
};
</script>
